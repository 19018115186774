import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Fieldset } from 'primereact/fieldset';
import { Dialog } from 'primereact/dialog';
import { MultiSelect } from 'primereact/multiselect';
import './styles.css';
import { AdvancedSearchComponent, constructElasticQuery, removeQueryIds } from '@scioservices/advanced-search-library/dist';
import '@scioservices/advanced-search-library/dist/css/querybuilder.css';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useAuth0 } from '@auth0/auth0-react';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { useQueryClient } from '@tanstack/react-query';
import { Chart, DynamicTable, SearchGuide } from './components';
import { DatapoolHeader, Loading } from '../../components';
import { sortArrayOfObjectsByPropertyValue } from '../../utils/functions';
import {
	useCreateApi,
	useExportPackage,
	useGenerateShareableLink,
	useGetDatapoints,
	useGetHistogramData, useGetJsonResults, useGetLinkByUUID,
	useGetSearchResults,
} from './queryHooks';

const AdvancedSearchPage = () => {
	const [valid, setValid] = useState(false);
	const [histogramVariable, setHistogramVariable] = useState({});
	const [lazyState, setlazyState] = useState({
		first: 0,
		rows: 50,
		page: 1,
	});
	const [loading, setLoading] = useState(false);
	const [toggleUniqueIdsDialog, setToggleUniqueIdsDialog] = useState(false);
	const [datasetIdExists, setDatasetIdExists] = useState(false);
	const [triggerSearchResults, setTriggerSearchResults] = useState(false);
	const [histogramData, setHistogramData] = useState(false);
	const [createApiDialog, setCreateApiDialog] = useState(false);
	const [apiName, setApiName] = useState('');
	const [createApiLoading, setCreateApiLoading] = useState(false);
	const [createShareableLinkLoading, setCreateShareableLinkLoading] = useState(false);
	const [query, setQuery] = useState();
	const [guideVisible, setGuideVisible] = useState(false);
	const [downloadOption, setDownloadOption] = useState([]);
	const [downloadCase, setDownloadCase] = useState('');

	const downloadOptions = [
		{
			label: 'Schemas',
			code: 'Schemas',
			items: [
				{ label: 'CAROB', value: 'CAROB' },
				{ label: 'ICASA', value: 'ICASA' },
			],
		},
		{
			label: '--------------',
			code: 'other',
			items: [
				{ label: 'Associated Documents, Metadata & Codebook', value: 'Associated Documents, Metadata & Codebook' },
			],
		},
		{
			label: 'Download',
			code: 'download',
			items: [],
		},
	];

	const navigate = useNavigate();

	const toast = useRef(null);

	const { user, isAuthenticated } = useAuth0();

	const location = useLocation();

	const queryClient = useQueryClient();

	const [searchParams, setSearchParams] = useSearchParams();

	const getShareableLink = useGetLinkByUUID(user?.sub, searchParams.get('linkId'), toast, navigate, isAuthenticated);

	const selectedDatapool = useMemo(() => {
		if (location.state) {
			return location.state.selectedDatapool;
		}
		if (searchParams.get('selectedDatapool')) {
			const selectedDp = searchParams.get('selectedDatapool');
			const decodedDatapool = atob(selectedDp);
			const dp = JSON.parse(decodedDatapool);
			return JSON.parse(dp.selectedDatapool);
		}
		if (getShareableLink.data) {
			const stringData = getShareableLink.data.selectedDatapool;
			return JSON.parse(stringData);
		}
		return {};
	}, [getShareableLink.isSuccess]);

	const inputQuery = useMemo(() => {
		if (searchParams.get('selectedDatapool')) {
			const selectedDp = searchParams.get('selectedDatapool');
			const decodedDatapool = atob(selectedDp);
			const dp = JSON.parse(decodedDatapool);
			setQuery(dp.query);
			return dp.query;
		}
		if (getShareableLink.data) {
			setQuery(getShareableLink.data.query);
			return getShareableLink.data.query;
		}
		return null;
	}, [getShareableLink.isSuccess]);

	const mongoQuery = useMemo(() => {
		if (query) {
			return removeQueryIds(query);
		}
		return null;
	}, [query]);

	const elasticQuery = useMemo(() => {
		if (mongoQuery) {
			const elastic = constructElasticQuery(JSON.stringify(mongoQuery));
			return elastic[0];
		}
		return null;
	}, [mongoQuery]);

	const jsonResults = useGetJsonResults(selectedDatapool.alias, elasticQuery, selectedDatapool.status, user?.sub, downloadCase);

	const pkg = useExportPackage(selectedDatapool.alias, elasticQuery, selectedDatapool.status, user?.sub, selectedDatapool.mongo_id, downloadCase);

	const datapoints = useGetDatapoints(selectedDatapool.alias, selectedDatapool.status, user?.sub);

	const searchResults = useGetSearchResults(selectedDatapool.alias, lazyState, elasticQuery, selectedDatapool.status, user?.sub, setHistogramVariable, triggerSearchResults, setTriggerSearchResults);

	const chartData = useGetHistogramData(histogramVariable.name, selectedDatapool.alias, elasticQuery, selectedDatapool.status, histogramData, setHistogramData);

	const createApiMutation = useCreateApi(user?.sub, selectedDatapool, toast, setCreateApiDialog, queryClient, setCreateApiLoading);

	const createShareableLink = useGenerateShareableLink(user?.sub, selectedDatapool, toast, setCreateShareableLinkLoading);

	useEffect(() => {
		if (downloadCase) {
			switch (downloadCase) {
			case 'all': handleDownload(pkg); break;
			case 'carobicasa': handleDownload(pkg); break;
			case 'carobfiles': handleDownload(pkg); break;
			case 'icasafiles': handleDownload(pkg); break;
			case 'files': handleDownload(pkg); break;
			case 'carob': handleDownload(jsonResults); break;
			case 'icasa': handleDownload(jsonResults); break;
			default: break;
			}
		}
	}, [downloadCase]);

	const createApi = () => {
		setCreateApiLoading(true);
		createApiMutation.mutate({ apiName, mongoQuery });
	};

	const filters = () => {
		let newFilters = selectedDatapool.filters;
		if (!(newFilters instanceof Array)) return [];
		newFilters = newFilters.map((item) => {
			const temp = { ...item, valueEditorType: item.type };
			if (item.values) {
				let sortedValues = sortArrayOfObjectsByPropertyValue(item.values, 'label');
				sortedValues = sortedValues.map((val) => {
					let newLabel = val.label;
					let newName = val.name;
					if (val.label === 'na') {
						newLabel = 'NA';
					}
					if (val.name === 'na') {
						newName = 'NA';
					}
					return { label: newLabel, name: newName };
				});
				temp.values = sortedValues;
			}
			delete temp.validator;
			return temp;
		});
		newFilters = sortArrayOfObjectsByPropertyValue(newFilters, 'label');
		return newFilters;
	};

	const getSearchResults = () => {
		if (query && valid) {
			setTriggerSearchResults(true);
		}
	};

	const datapointsArray = () => {
		if (datapoints?.data instanceof Array) {
			return datapoints?.data;
		}
		return [];
	};

	const generateShareableLink = () => {
		const sharableData = { selectedDatapool: JSON.stringify(selectedDatapool), query: mongoQuery };
		if (isAuthenticated) {
			setCreateShareableLinkLoading(true);
			createShareableLink.mutate(sharableData);
		} else {
			const encodedData = btoa(JSON.stringify(sharableData));
			const url = `${process.env.REACT_APP_URL}/AdvancedSearch?selectedDatapool=${encodedData}`;
			const type = 'text/plain';
			const blob = new Blob([url], { type });
			const data = [new ClipboardItem({ [type]: blob })];
			navigator.clipboard.write(data);

			// navigator.clipboard.writeText(url);

			toast.current.show({ severity: 'success', summary: 'Success!', detail: 'Link was generated successfully and copied to your clipboard' });
		}
	};

	const handleDownload = async (downloadFile) => {
		const url = await downloadFile.refetch();
		if (url.data) {
			const link = document.createElement('a');
			link.href = url.data;
			link.setAttribute('download', '');
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	};

	const downloadCases = () => {
		const carob = downloadOption.includes('CAROB');
		const icasa = downloadOption.includes('ICASA');
		const documents = downloadOption.includes('Associated Documents, Metadata & Codebook');
		if (carob && icasa && documents) {
			setDownloadCase('all');
		} else if (carob && icasa) {
			setDownloadCase('carobicasa');
		} else if (carob && documents) {
			setDownloadCase('carobfiles');
		} else if (icasa && documents) {
			setDownloadCase('icasafiles');
		} else if (documents) {
			setDownloadCase('files');
		} else if (carob) {
			setDownloadCase('carob');
		} else if (icasa) {
			setDownloadCase('icasa');
		}
	};

	const renderHistogram = () => {
		if (query && valid && (selectedDatapool?.histogramVariables instanceof Array)) {
			if (selectedDatapool?.histogramVariables.length) {
				return (
					<div className="variable-distribution">
						<p>Variable Distribution</p>
						<Dropdown
							value={histogramVariable}
							options={selectedDatapool?.histogramVariables}
							onChange={(e) => {
								setHistogramData(true);
								setHistogramVariable(e.value);
							}}
							optionLabel="label"
						/>
						{
							chartData.isLoading || chartData.data
								? (
									<Loading loading={chartData.isLoading}>
										<Chart chartData={chartData.data} />
									</Loading>
								)
								: null
						}

					</div>
				);
			}
		}
		return null;
	};
	const renderSearchResults = () => {
		const groupedItemTemplate = (option) => {
			if (option.label === 'Download') {
				return (
					<Button
						label="Download"
						onClick={() => {
							downloadCases();
						}}
					/>
				);
			}
			return (
				<div className="flex align-items-center">
					<div>{option.label}</div>
				</div>
			);
		};

		return (
			<Loading loading={searchResults.isLoading}>
				{
					searchResults.isSuccess
						? (
							<>
								<div className="table-containers">
									<div className="header">
										<p>Results</p>
										{query && valid
											? (
												<div className="header-buttons">
													<MultiSelect
														value={downloadOption}
														options={downloadOptions}
														onChange={(e) => setDownloadOption(e.value)}
														optionLabel="label"
														optionGroupLabel="label"
														optionGroupChildren="items"
														optionGroupTemplate={groupedItemTemplate}
														display="chip"
														// className="w-full md:w-20rem"
														placeholder="Download"
														showSelectAll={false}
													/>
												</div>
											)
											: null}
									</div>
									<DynamicTable
										setDatasetIdExists={setDatasetIdExists}
										datasetIdExists={datasetIdExists}
										searchResults={searchResults}
										lazyState={lazyState}
										setlazyState={setlazyState}
										getSearchResults={getSearchResults}
										setTriggerSearchResults={setTriggerSearchResults}
									/>
								</div>
								{
									datasetIdExists
										? (
											<div className="datapoints">
												<p>DATAPOINTS retrieved from ({datapoints?.data?.length}) datasets</p>
												<Button
													label="View Dataset IDs"
													onClick={() => {
														setToggleUniqueIdsDialog(true);
													}}
													disabled={!datapoints?.data?.length}
												/>
											</div>
										)
										: null
								}

							</>
						)
						: null
				}
			</Loading>
		);
	};

	return (
		<div className="advanced-search-page">
			<Toast ref={toast} />
			<Loading loading={!selectedDatapool?.filters}>
				<DatapoolHeader
					datapool={selectedDatapool}
					headerState={{ codebook: true, api: query && valid && user?.sub }}
					openCreateApiDialog={setCreateApiDialog}
				/>
			</Loading>
			<Loading loading={!selectedDatapool?.filters}>
				<Fieldset legend="Search">
					<p style={{ padding: '0px 24px' }}>
						Answer questions like “I would like to find all datasets for maize grown in Sub-Saharan Africa
						with nitrogen applied as inorganic fertilizer” by picking the following search terms: (1)
						“region” = Sub-Saharan Africa; (2) “crop” = maize; (3) ‘’N applied in inorganic fertilizer
						(kg/ha)’’ &gt; 0
					</p>
					<div className="advanced-search-component">
						{
							selectedDatapool?.filters
								? (
									<AdvancedSearchComponent
										addIcon={<i className="fa-solid fa-plus" />}
										deleteIcon={<i className="fa-solid fa-x" />}
										query={query}
										setQuery={setQuery}
										setValid={setValid}
										filters={filters()}
									/>
								)
								: null
						}
						<div className="actions">
							<Button
								label="Get Link"
								disabled={!valid}
								onClick={() => generateShareableLink()}
								loading={createShareableLinkLoading}
							/>
							<Button
								label="Search"
								disabled={!valid}
								onClick={() => getSearchResults()}
							/>
							<Button
								className="h-11 w-28"
								label="Guide"
								iconPos="left"
								icon="fa-regular fa-book"
								style={{
									background: 'rgb(0, 150, 136)',
									borderColor: 'rgb(1,134,121)',
								}}
								onClick={() => setGuideVisible(true)}
							/>
						</div>
					</div>
				</Fieldset>
			</Loading>
			{renderSearchResults()}
			{renderHistogram()}
			<Dialog header="Datasets" visible={toggleUniqueIdsDialog} style={{ width: '50vw' }} onHide={() => setToggleUniqueIdsDialog(false)}>
				<DataTable
					value={datapointsArray()}
					showGridlines
					stripedRows
					paginator
					rows={10}
					paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
					currentPageReportTemplate="{first} - {last} of {totalRecords}"
				>
					<Column field="name" header="Dataset Id" />
					<Column field="count" header="Datapoints" />
				</DataTable>
			</Dialog>
			<Dialog header="Create Api" visible={createApiDialog} style={{ width: '80vw' }} onHide={() => setCreateApiDialog(false)}>
				<div className="advanced-search-create-api">
					<h4>API Name</h4>
					<InputText value={apiName} onChange={(e) => setApiName(e.target.value)} />
					<Button label="Create API" onClick={() => createApi()} disabled={!apiName} loading={createApiLoading} />
				</div>
			</Dialog>
			<SearchGuide visible={guideVisible} setVisible={setGuideVisible} />
		</div>
	);
};

export default AdvancedSearchPage;
